.container-chatinput {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 24px;
  height: 40px;
  
}

.User-Mention-container {
  box-shadow: 0px 0px 3px black;
  display: flex;
  flex-direction: column-reverse;
  max-height: 240px;
  position: absolute;
  background-color: white;
  bottom: 50px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 transparent; /* For Firefox */
  /* For Webkit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7f1010;
  }
}

.input-container-absolute {
  border: 1px solid #8d8d8e;
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
}
.input-container {
  border: 0px solid #8d8d8e;
  border-radius: 4px;
  position: relative;
  background-color: white;
}

.input-container form {
  display: flex;
  /* height: 42px; */
  padding: 6px 0;
  align-items: end;
  padding-left: 10px;
}
/* .input-container form input {
 border: 2px solid black;
} */

.input-to-show{
  position: absolute;
  
  left: 11.36px;
  background-color: white;
  font-size: 13px;
}

.input-container input {
  flex: 1;
  border: none;
  font-size: 13px;
}

.input-container input:focus {
  outline: none;
}

.send-button {
  background: #007a5a;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  border: none;
}

.send-button:hover {
  background: #148567;
}

.send-icon {
  color: #d9d9d9;
  width: 18px;
}

.upload-button {
  background: #016a81;
  border-radius: 2px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  border: none;
}

.upload-button:hover {
  background: #01596d;
}

.upload-icon {
  color: #d9d9d9;
  width: 18px;
  font-size: 17px;
  margin-top: 4px;
  font-weight: 700;
}

.restricted-message {
  padding: 17px;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  background-color: black;
  color: white;
  /* box-shadow: 0px -4px 2px rgb(108, 108, 108); */
}

@media screen and (max-width: 900px) {
  .container-chatinput {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
}


.ChatInput-UserDetails{
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 40px;
  width: 100%;
  gap: 10px;
  padding-left: 15px;
  font-weight: 600;
  font-size: 15px;
}
.ChatInput-UserDetails-hover{
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 40px;
  width: 100%;
  gap: 10px;
  padding-left: 15px;
  font-weight: 600;
  font-size: 15px;
  background-color: rgb(232, 232, 232);
}


.ChatInput-UserDetails-hover img {
  width: 25px;
  border-radius: 50%;
  
  height: 25px;
}
.ChatInput-UserDetails img {
  width: 25px;
  border-radius: 50%;
  
  height: 25px;
}