.emoji-picker-comp {
    display: flex;
    justify-content: space-around;
    padding-bottom: 7px;
    padding-top: 4px;
    padding-left: 13px;
    padding-right: 13px;
    box-shadow: 0px 0px 2px black;
    border-radius: 23px;
    height: fit-content;
    width: fit-content;
    gap: 10px;
  }
  
  .emoji-item-comp {
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  
  .emoji-item-comp:hover {
    transform: scale(1.3);
  }
  
  .selected-comp {
    background-color: #b3e0ff; /* Change the background color for the selected emoji */
  }