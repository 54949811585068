
.container-userlist{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 25px;
    gap: 20px;
}

.search-box {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 450px;
    margin: 0pc auto;
  }
  
  .search-box input {
    border: none;
    outline: none;
    flex: 1;
    padding: 6px;
    font-size: 14px;
  }
  
  .search-icon {
    font-size: 18px;
    color: #555;
    cursor: pointer;
  }

.userlist-table{
    margin: 0px 4vw;
    max-height: 75dvh;
    overflow: hidden;
    /* margin-top: 20px;
    margin: auto; */
    border: 1px solid black;
    border-radius: 5px;
    text-align: center;
    

}

.alluserinfo {
    overflow-y: scroll;
    max-height: 70dvh;
}

.alluserinfo::-webkit-scrollbar {
    width: 4px; /* Adjust the width as needed */
}

.alluserinfo::-webkit-scrollbar-thumb {
    background-color: #aaa; /* Color of the thumb */
}


.userinfo-header{
    font-weight: 700;
    display: flex;
    padding: 10px 0;
    background-color: black;
    color: white;
}
.userinfo-header-name{
    width: 24%;
}
.userinfo-header-email{
    width: 29%;
}
.userinfo-header-date{
    width: 18%;
}
.userinfo-header-role{
    width: 13%;
}
.userinfo-header-department{
    width: 13%;
}

.userinfo{
    display: flex;
    height: fit-content;
}

.username{
    width: 24%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 3px;
}
.reddot{
    background-color: rgb(245, 27, 27);
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    min-height: 10px;
    margin-top: 4px;
    border-radius: 50%;

}
.greendot{
    background-color: rgb(5, 177, 5);
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    min-height: 10px;
    margin-top: 4px;
    border-radius: 50%;

}
.email{
    width: 29%;
}
.joindate{
    width: 18%;
}
.userrole{
    width: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.userrole-dropdown{
    height: fit-content;
}

.userdepartment{
    width: 13%;
    display: flex;
    align-items: center;
    justify-content: center;

}
.DM{
    cursor: pointer;
    font-size: 13.4px;
    font-weight: 600;
    color: white;
    background-color: rgb(87, 86, 86);
    padding: 3px 6px;
    border-radius: 6%;
}
.DM:hover{
    background-color: rgb(59, 59, 59);
    box-shadow: 0px 0px 3px gray;
}