/* Task.css */

.container-task {
  display: flex;
  flex-wrap: wrap;
}

.main-task {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 20px;
}

.card-task {
  border-radius: 6px;
  background: #F3F3F3;
  width: 216px;
  height: 118px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 10px;
}

.item {
  color: #000;
  font-size: 13px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin: 4px;
}

.task-item {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 4px;
  margin-bottom: 0px;
}

.tags {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -16px;
  margin-bottom: -10px;
}


.comment-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.line {
  width: 365px;
  height: 1px;
  background: #ADADAD;
  margin-top: 7px;
  margin-left: -10px;
}

.comment {
  border-radius: 6px;
  border: 1px solid #717171;
  width: 346px;
  min-height: 55px;
  flex-shrink: 0;
  background: #FFF;
  margin-top: 14px;
  color: #000;
}

.comment-header {
  display: flex;
  margin: 7px;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 14px;

  font-weight: 400;
}

.comment-body {
  margin: 8px;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 3px;
}

.task-container {
  position: fixed;
  bottom: 0;
  /* Ensure it covers the full viewport height */
}