.container-chatmessage {
    display: flex;
    /* align-items: center;  */

    word-wrap: break-word;
    width: 100%;

}


.PinMessage{
font-size: 36px;
cursor: pointer;
position: relative;
}

.Pinmessage-Pin{
    display: none;
}

.PinMessage:hover .Pinmessage-Pin{
    display: flex;
    position: absolute;
    background-color: black;
    color: white;
    padding: 2px 7px;
    border-radius: 3px;
    top: 0px;
    left: 0px;
}

.container-ownchatmessage {
    display: flex;
    /* align-items: center;  */
    justify-content: end;
    flex-direction: row-reverse;
    word-wrap: break-word;

}



.text-container {
    display: flex;
    padding: 10px 20px;
    background-color: rgba(134, 11, 182, 0.112);
    border-radius: 8px;
    margin: 13px;
    word-wrap: break-word;
    max-width: 55%;
    position: relative;
}


.owntext-container {
    display: flex;
    padding: 10px 20px;
    background-color: rgba(14, 194, 134, 0.178);
    border-radius: 8px;
    margin: 13px;
    word-wrap: break-word;
    max-width: 55%;
    position: relative;
}

.reactionCountpresent {
    margin-bottom: 35px;
}

.user-avatar {
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    /* overflow: hidden; */
    margin-right: 8px;

    position: relative;
}

.user-avatar img {
    width: 100%;
    border-radius: 50%;
}

.gotochat {
    position: absolute;
    width: 100px;
    left: 0px;
    top: -22px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    background-color: black;
    color: white;
    text-align: center;
    padding: 4px 2px;
    border-radius: 5px;
    z-index: 5;
}

.gotochathide {
    display: none;
}

.message-content {
    display: flex;
    flex-direction: column;
    max-width: 91%;
    gap: 5px;
    padding: 5px 0;

}

.message-contentown {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 5px;
    padding: 5px 0;
}

.name {

    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 15px;
    line-height: 1.4;
    text-align: start;
}


.name span {
    margin-left: 8px;
    font-weight: 400;
    color: rgb(97, 96, 97);
    font-size: 13px;
}


.nameown {
    font-weight: 800;
    font-size: 15px;
    line-height: 1.4;
    text-align: end;
    font-family: 'Inter', sans-serif;
}

.nameown span {
    margin-left: 8px;
    font-weight: 400;
    color: rgb(97, 96, 97);
    font-size: 13px;
}

.text {
    text-align: start;
    max-width: 100%;
    text-wrap: wrap;
    font-size: 15.1px;
}

.file {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    text-align: start;
    max-width: 100%;
    text-wrap: wrap;
    font-size: 13px;
    padding: 0px 14px;
    box-shadow: 0px 0px 4px black;
    border-radius: 4px;

}





audio {
    width: 100%;
    max-width: 400px;
    min-width: 400px;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #f1f3f4;
}


audio::-webkit-media-controls-play-button {
    background-color: #3498dbb7;
    border: none;
    border-radius: 50%;
    color: #fff;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
    color: #333;
}








.fileimage {
    max-width: 30vw;
    max-height: 25vw;

}

.fileimage img {
    max-width: 30vw;
    max-height: 25vw;
    box-shadow: 0px 0px 5px rgb(146, 146, 146)
}


.download {
    font-size: 16px;
    cursor: pointer;
}

.filename {
    display: flex;
    align-items: center;
    gap: 5px;
}

.filetype {
    font-size: 22px;
}

.EmojiSelect {
    position: absolute;
    right: -70px;
    top: 35%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    cursor: pointer;
}


.EmojiSelectOwn {
    position: absolute;
    left: -40px;
    top: 35%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    cursor: pointer;
    /* background-color: brown; */
}





.reaction-bar {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: -520px;
    z-index: 10;

}

.reaction-barown {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: -180px;
    z-index: 10;
}



.reaction-countown {
    position: absolute;
    bottom: -14px;
    height: 20px;
    background-color: rgb(149, 220, 197);
    box-shadow: 0px 0px 2px black;
    max-width: 90%;

    padding: 1px 6px;
    display: flex;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;

}


.reaction-count {
    position: absolute;
    bottom: -14px;
    height: 20px;
    background-color: rgb(115, 17, 154);
    box-shadow: 0px 0px 2px black;
    max-width: 90%;
    padding: 1px 6px;
    color: white;
    display: flex;
    gap: 10px;
    border-radius: 5px;
    cursor: pointer;
}


.reaction-details {
    display: flex;
    align-items: center;
}



.reactions {
    display: flex;
    align-items: center;
}

.reactions-emoji {
    margin-top: 10px;
}

.reactions-count {
    font-size: 14px;
    font-weight: 600;
}


.modal-content-reaction {
    background-color: aqua;
}




.reaction-container {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 1px 10px;
    max-height: 50px;
    min-height: 50px;
    width: 330px;
    justify-content: space-between;
    cursor: pointer;
}

.reaction-container:hover {
    background-color: aliceblue;
}

.reaction-image-username {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-image-reaction {
    max-width: 24px;
    max-height: 24px;
    border-radius: 50%;
}

.username-reaction-text {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

}

.username-reaction {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Inter', sans-serif;


}

.remove-reaction {
    position: absolute;
    font-size: 12px;
    font-weight: 300;
    color: rgba(255, 0, 0, 0.616);
    width: 200px;
    text-align: start;
    top: 20px;
    left: 0;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    /* You can adjust the width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Change the color as needed */
}