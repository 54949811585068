.Notes-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    padding: 20px 0;
}
.Notes-container::-webkit-scrollbar{
    width:10px;
}

.notes-box{
    width: 360px;
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
    
    background-color: rgb(239, 239, 239);
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgb(95, 95, 95);
    display: flex;
    flex-direction: column;
}

.noteheading{
    padding: 10px;
    border-bottom: 1px solid rgb(17, 132, 247);
    word-wrap: break-word;
    font-weight: 600;
}
.noteheadingpinned{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid rgb(17, 132, 247);
    word-wrap: break-word;
    font-weight: 600;
}
.notecontent{
    padding: 10px;
    word-wrap: break-word;
    margin-bottom: 10px;
    padding-bottom: 10px;
    
}

.notes{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding-left: 20px; 
    
}
.notes-actions{
    display: flex;
    gap: 10px;
    cursor: pointer;
}
.notes-pinned{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    border-bottom: 2px solid gray;
    padding-bottom: 70px;
    padding-left: 20px;
    
}