.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0px 4px rgba(0, 0, 0, 0.817);
    width: fit-content;
    height: fit-content;
    margin: 0 auto;
    margin-top: 50px;
    min-width: 400px;
   
  }
  
  .profile-image {
    margin-bottom: 20px;
   
  }
  
  .profile-image img {
    
    border-radius: 50%;
    overflow: hidden;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .profile-details h2 {
    margin: 10px 0;
    font-size: 1.8em;
    color: #333;
    
  }
  
  .profile-details .detail {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }
  
  .detail .icon {
    margin-right: 10px;
    color: #3498db;
    width: 5%;
   
  }
  
  .detail{
    width: 400px;
  }
  .detail p {
    margin: 0;
    font-size: 1.1em;
    text-align: start;
    width: 60%;
  }
  .detail span {

    width: 35%;
    text-align: start;
    margin: 0;
    font-size: 1.1em;
    font-weight: 600;
    color: #424242;
  }

  
  


 
  
  .userprofile-tags-adder {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: 20px;
  }
  
  .userprofile-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .userprofile-input-container input {
    border: none;
    outline: none;
    padding: 8px;
    border-radius: 2px;
    font-size: 14px;
    border: 1px solid transparent;
    flex-grow: 1;
  }
  
  .userprofile-input-container input:focus {
    border: 1px solid #3498db;
  }
  
  .userprofile-add-button {
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 3px;
    padding: 8px 12px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .userprofile-add-button:hover {
    background-color: #2980b9;
  }
  
  .userprofile-tags-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .userprofile-tag {
    display: flex;
    align-items: center;
    background-color: #ccc;
    color: #333;
    border-radius: 12px;
    padding: 5px 10px;
    margin: 5px;
    font-size: 14px;
  }
  
  .userprofile-delete-icon {
    cursor: pointer;
    margin-left: 5px;
  }