.Broadcast-Container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* margin: 0vh 20vw; */
    margin-top: 4vh;
    /* margin-bottom: 5vh; */
    /* position: relative; */
}

.spinner-container-broadcast{
  position: absolute;
  background-color: rgba(24, 24, 24, 0.767);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  z-index: 40;
}
.spinner-container-broadcast div{
  color: white;
  font-size: 31px;
  font-weight: 600;
}

.Broadcast-Heading{
  font-weight: 600;
  font-size: 25px;
  margin: 0 auto;
  text-align: center;
}
.Broadcast-dropdown
{
    /* width: 500px; */
    width: 100%;
    border-radius: 5px;
    background-color: #EFEFEF;
    width:40vw;
    margin: 0 auto;
    /* border: 2px solid black; */
    box-shadow: 0px 0px 5px black;
    height: fit-content;
    max-height: 65vh;
    overflow-y: auto;
}
/* Style the scrollbar */
.Broadcast-dropdown::-webkit-scrollbar {
    width: 4px; 
}

/* Track */
.Broadcast-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.Broadcast-dropdown::-webkit-scrollbar-thumb {
    background: #888; 
}



.dropdown-item {
    border-bottom: 1px solid #868686;
  }
  
  .department-name {
    cursor: pointer;
    padding: 13px 10px;
    font-weight: 600;
  }
  
  .arrow {
    float: right;
  }
  
  .users {
    display: none;
    background-color: white;
    border-top: 1px solid #868686;
  }
  
  .users.show {
    display: block;
  }
  
  .broadcast-username {
    padding: 7px;
    padding-left: 20px;
  }
  

  .SendBroadcast{
    background-color: #0075FF;
    padding: 6px 15px;
    margin: auto;
    border-radius: 5px;
    color: white;
    cursor: pointer;
  }
  .SendBroadcast:hover{
    background-color: #046de4;
    box-shadow: 0px 0px 10px rgb(221, 221, 221);
   
  }