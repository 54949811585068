.container-chat {

    overflow-x: hidden;
    display: grid;
    overflow-y: hidden;
    grid-template-rows: 64px auto min-content;
    min-height: 0;
}

.channel {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.channel-details {
    display: flex;
    align-items: center;
    color: #606060;
}

.channel-name {
    font-weight: 700;
}

.channel-info {
    font-weight: 400;
    display: flex;
    align-items: end;
    gap: 10px;
    position: relative;
    color: #606060;
    font-size: 13px;
    margin-top: 8px;
}
.channel-desc{
    margin-bottom: 2px;
}
.channel-desc-info{
    cursor: pointer;
}

.showdesc-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 49;
}

.channel-description{
    position: absolute;
    top: 30px;
    z-index: 50;
    box-shadow: 0px 0px 5px rgb(99, 99, 99);
    padding: 10px;
    
}
 
.channel-description textarea{
    height: 330px ;
    padding: 6px;
    width: 45vw;
}

.ViewChannelDesc{
    height: 330px ;
    padding: 6px;
    width: 45vw;
    background-color: white;
    border: 0;
    color: black;
    overflow-y: auto;
}


.channel-description textarea::-webkit-scrollbar {
    width: 0px;
}

.desc-icons{
    display: flex;
    flex-direction: row-reverse;
    font-size: 18px;
    color: black;
    gap: 10px;
    cursor: pointer;
}




.more-chat {
    cursor: pointer;
    border: 2px solid black;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

}

.more-chat-icon {
    font-size: 20px;
}


.header {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(83, 39, 83, .13);
    justify-content: space-between;
}

.message-container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: red;
    position: relative;
    /* Optional: Set the scrollbar color for Firefox */

    /* For WebKit-based browsers like Chrome and Safari */
    &::-webkit-scrollbar {
        width: 6px;
        /* Adjust the width as needed */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c3c5c8;
        /* Optional: Set the thumb color for WebKit browsers */
    }
}

.unread-messages-seperator{
    background-color: #606060;
    padding: 6px 0;
    color: white;
    text-align: center;
    font-weight: 600;
}

.usernames-container {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 100px;
}

.username-container-p {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.username-container-p:hover {
    background-color: red;
}