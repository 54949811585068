
.container-sidebar {
    background: #0C0829;
    height: 100vh;
    position: absolute;
    width: 260px;
    top: 0;
}

.reddot-sidebar {
    background-color: rgb(245, 27, 27);
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    min-height: 10px;
    /* margin-top: 4px; */
    border-radius: 50%;

}

.greendot-sidebar {
    background-color: rgb(5, 177, 5);
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    min-height: 10px;
    /* margin-top: 4px; */
    border-radius: 50%;

}

.workspace-container-sidebar {
    color: white;
    /* height: 64px; */
    display: flex;
    align-items: center;
    padding-left: 19px;
    padding-right: 19px;
    justify-content: center;
    border-bottom: 1px solid #532753;
    height: 110px;
    
    
}
.workspace-container-sidebar img {
    width: 180px;
    height: 80px;
}


.new-message-sidebar {
    width: 37px;
    height: 37px;
    color: #BCABBC;
    fill: #3F0E40;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;
}

.main-channels-sidebar {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}

.main-channel-item-sidebar {
    color: rgb(188, 171, 188);
    display: grid;
    grid-template-columns: 15% auto;
    height: 28px;
    align-items: center;
    padding-left: 19px;
    cursor: pointer;
    text-align: start;
    width: 220px;
    border-radius: 5px;
}

.active-pathSidebar {
    color: #BCABBC;
    display: grid;
    grid-template-columns: 15% auto;
    height: 28px;
    align-items: center;
    padding-left: 19px;
    cursor: pointer;
    text-align: start;
    width: 220px;
    padding-right: 5px;
    border-radius: 5px;
    background-color: #491675;

}


.channels-container-sidebar {
    color: #BCABBC;
    margin-top: 10px;
}

.new-channel-container-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    padding-left: 19px;
    padding-right: 12px;
    font-weight: 500;
    background-color: #342C6F;
}

.task-container-sidebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
}

.channels-list-sidebar {
    /* Add your channels list styles here */
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 10px;
    align-items: center;

}
.personalchat-list-sidebar {
    /* Add your channels list styles here */
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 10px;
    align-items: center;
    margin-bottom: 100px;

}

.channel-sidebar {
    color: rgb(188, 171, 188);

    align-items: center;
    padding-left: 19px;
    cursor: pointer;
    text-align: start;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 220px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.channel-sidebar p {
    display: flex;
    align-items: center;
    gap: 4px;
}

.unread-messages{
    background-color: black;
    font-size: 12.5px;
    font-weight: 600;
    padding: 1.1px 6px;
    border-radius: 3px;

}

.channel-sidebar-active {
    color: rgb(188, 171, 188);
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: #491675;
    align-items: center;
    padding-left: 19px;
    cursor: pointer;
    text-align: start;
    width: 220px;
    border-radius: 5px;
    display: flex;
}
.channel-sidebar-active p {
    display: flex;
    align-items: center;
    gap: 4px;
}


.channel-sidebar:hover {
    background: #49167538;
}

.scroll-sidebar {
    height: 89%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-sidebar::-webkit-scrollbar {
    width: 0px;
    /* Adjust the width as needed */
}

.scroll-sidebar::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scrollbar thumb */
    border-radius: 0px;
    /* Rounded corners for the thumb */
}

.scroll-sidebar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Color of the scrollbar track */
}