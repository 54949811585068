.PinnedMessages-Container{
    background-color: rgb(205, 205, 205);
    /* height: 40px; */
    padding: 6px;
    display: flex;
    width: 100%;
    position: fixed;
    flex-direction: column;
    gap: 4px;
    top: 109px;
}

.PinnedMessages-Messages{
    display: flex;
    gap: 20px;
}

.PinnedMessages-Unpin{
    cursor: pointer;
}