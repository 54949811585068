.AddmodalContainer{
    width: 100vw;
    height: 100vh;
    position: absolute;
    
    /* z-index: 50; */
}
.hidemodalnotes{
    display: none;

}


.subAddmodalContainer{
    width: 100vw;
    height: 100vh;
    position: relative;
    
}

.Addmodalremove{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.537);
    width: 100vw;
    height: 100vh;
    z-index: 49;
}
.AddmodalMain{
    background-color: rgb(227, 226, 226);
  border-radius: 5px;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  z-index: 50;
}

.notes-title{
    width: 430px;
    padding: 7px 10px;
    border: 2px solid gray;
    border-radius: 4px;
}
.notes-title:focus {
    outline: none;
  }

.notes-input{
    width: 430px;
    height: 180px;
    padding: 7px 10px;
    border: 2px solid gray;
    border-radius: 4px;
}
.notes-input:focus {
    outline: none;
  }

.pinandaddnotes{
    display: flex;
    width: 420px;
    justify-content: space-between;
}
.pinandaddnotes{
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.addnote{
    padding: 6px 10px ;
    color: white;
    border-radius: 4px;
    background-color: rgb(25, 143, 253);
    cursor: pointer;
}
.pinnote{
    padding: 6px 10px ;
    color: white;
    border-radius: 4px;
    background-color: rgb(109, 109, 109);
    cursor: pointer;
}

.Deletenote{
    padding: 6px 10px ;
    color: white;
    border-radius: 4px;
    background-color: rgb(236, 45, 80);
    cursor: pointer;

}
.Deletenote:hover{
    padding: 6px 10px ;
    color: white;
    border-radius: 4px;
    background-color: rgb(237, 23, 62);
    cursor: pointer;

}