.Previewfile-Container{
    position: fixed;
    top: 45px;
    z-index: 51;
    background-color: rgb(225, 217, 247);
    height: 100%;
    padding: 20px;
}

.Cross{
    cursor: pointer;
    width: fit-content;
}

.Previewfile-image{
    
    display: flex;
    justify-content: center;
}
.Previewfile-image img{
    max-width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgb(171, 171, 171);
    max-height: 70vh;
}

.Previewfile-buttons{
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.Previewfile-buttons div{
    padding: 7px 15px;
    background-color: rgb(31, 161, 237);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    box-shadow: 0px 0px 4px rgb(143, 143, 143);
}
