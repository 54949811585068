.UserMentionContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    padding: 10px 20px;
    font-size: 15px;
}

.UserMentionContainer::-webkit-scrollbar {
    width: 4px; /* Set the width of the scrollbar */
}

.UserMentionContainer::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the scrollbar thumb */
}

.UserMentionContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the color of the scrollbar track */
}


.MentionedMessage{
display: flex;
flex-direction: column;
background-color: rgb(237, 237, 237);
box-shadow: 0px 0px 4px rgb(87, 87, 87);
gap: 0px;
border-radius: 10px;
padding: 10px;
cursor: pointer;

}

.mentionedmessageheader{
    margin: 5px 0;
}
.mentionedmessageheader span{
    font-weight: 600;

}

.Mentionedmessagedetails{
    display: flex;
    padding: 0;
    align-items: start;
    gap: 10px;
}

.Mentionedmessagedetails img{
    width: 36px;
    height: 36px;
    border-radius: 6px;
}

.usermentiontextandname{
    display: flex;
    flex-direction: column;
    gap: 7px;
    
}

.mentionedmessageusername{
    font-weight: 600;
}