.container-pchat {
    display: grid;
    grid-template-rows: 64px auto min-content;
    min-height: 0;
}

.PersonalChatUsername{
    display: flex;
    gap: 7px;
    align-items: center;
}
.userInactive{
    background-color: rgb(245, 27, 27);
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    min-height: 10px;
    /* margin-top: 4px; */
    border-radius: 50%;

}
.userActive{
    background-color: rgb(5, 177, 5);
    min-width: 10px;
    max-width: 10px;
    max-height: 10px;
    min-height: 10px;
    /* margin-top: 4px; */
    border-radius: 50%;

}


.pchannel-details {
    display: flex;
    align-items: center;
    color: #606060;
}

.pchannel-name {
    font-weight: 700;
}

.pchannel-info {
    font-weight: 400;
    color: #606060;
    font-size: 13px;
    margin-top: 8px;
    display: flex;
    align-items: center;
}

.pchannel-info img {
    height: 35px;
    width: 35px;
    border-radius: 50%;

}

.pchannel-info p {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}

.pinfo {
    /* Add your info styles here */
}

.pheader {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(83, 39, 83, .13);
    justify-content: space-between;
}

.pmessage-container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    position: relative;

    /* For WebKit-based browsers like Chrome and Safari */
    &::-webkit-scrollbar {
        width: 6px;
        /* Adjust the width as needed */
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c3c5c8;
        /* Optional: Set the thumb color for WebKit browsers */
    }
}

.pusernames-container {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 100px;
}

.pusername-container-p {
    margin: 5px 0;
    font-size: 16px;
    color: #333;
    cursor: pointer;
}

.pusername-container-p:hover {
    background-color: red;
}